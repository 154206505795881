import React, { useState, useEffect, useCallback } from "react";
import { getCKEditorTabsIds } from "../helpers/getCKEditorTabsIds";

const defaultValue = {
	quote: {},
	contentGrid: {},
	rowActions: [],
	ckeditor: {},
	handleCKEditorSelectedTabId: () => { },
	buildNotesDialog: () => { },
	handleSetCKEditor: () => { }
};
const EditorContext = React.createContext(defaultValue);
export const useEditorContext = () => React.useContext(EditorContext);

export function EditorContextProvider({ value, children }) {
	//List copied from QuoteTabsCustom.cs, along with a few that already existed in the original code
	//Probably should leave out Term Options as it has its own spcial handling
	const gridFormats = ["Cover Letter", "Executive Summary", "Pre Outline", "Post Outline", "Appendix",
		"Statement of Work", "SoW", "Scope of Work", "Quosal Cover Letter"];

	//TODO: refactor this to use the quote from the context
	const quote = value?.quote || app.currentQuote || value?.contentGrid?.props.quote;
	const [ckeditor, setCKEditor] = useState({});
	const [selectedTabs, setSelectedTabs] = useState([]);
	const [isEditorFullScreen, setIsEditorFullScreen] = useState(false);	

	const [shouldDisplayTabNumbers, setShouldDisplayTabNumbers] = useState(quosal.settings.getValue('displayTabNumbers', true));
	const leftContentExpanded = !quosal.util.isNewPreviewContentVisible();

	useEffect( () =>
		value.handleSetCKEditor(ckeditor)
		, [ckeditor]);

	useEffect(() => {
		if (leftContentExpanded) {
			setCKEditor({});
		}
	}, [leftContentExpanded]);

	const setSelectedTabsToDroppedTabs = useCallback(() => {
		const alreadyDroppedTabs = getCKEditorTabsIds(quote?.HTMLContentForAutoSaving);
		const selectedTabs = [];
		alreadyDroppedTabs.forEach((tabId) => {
			const tab = quote.Tabs.find((tab) => tab.IdQuoteTabs === tabId);
			if (tab) {
				selectedTabs.push({ tabId: tab.IdQuoteTabs, tabNumber: tab.TabNumber });
			}
		});
		setSelectedTabs(selectedTabs);
	}, [quote]);

	useEffect(() => setSelectedTabsToDroppedTabs(), []);
	const isLockedDownQuote =
		quote.IsLocked &&
		!quote.IsArchive &&
		!quote.IsTemplate;

	const isHistoricalQuote = quote.IsArchive && !quote.IsTemplate;

	const isArchivedQuote = isLockedDownQuote && quote.QuoteStatus === "Archived";

	const isDisabledQuoteActions = isHistoricalQuote || isArchivedQuote || isLockedDownQuote;

	const internalValue = {
		isEditorFullScreen,
		gridFormats,
		isDisabledQuoteActions,
		shouldDisplayTabNumbers,
		isQuoteTemplate: quote.IsTemplate,
		ckeditor,
		setCKEditor,
		selectedTabs,
		setSelectedTabs,
		setSelectedTabsToDroppedTabs,
		quote
	}

	return (
		<EditorContext.Provider value={{ ...value, ...internalValue }}>
			{children}
		</EditorContext.Provider>
	);
}

EditorContextProvider.Consumer = EditorContext.Consumer;
