import React from "react";

import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Chip, CircularProgress, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useEditorContext } from "./context/editorContext";
import { useSelectedRowContext } from "./TabsWrapper/SelectedRowContext";

export function columns(props) {
	const {isDisabledQuoteActions} = useEditorContext();
	const {cutRows, copiedRows} = useSelectedRowContext();
;
	const tooltip = props.isStandardUserAndProtectedTab 
		|| props.isStandardUserAndLockItemEdit 
		|| isDisabledQuoteActions
		? "View Product"
		: "Edit Product";
	const icon = props.isStandardUserAndProtectedTab 
		|| props.isStandardUserAndLockItemEdit 
		|| isDisabledQuoteActions 
		? ( <RemoveRedEyeOutlinedIcon /> ) 
		: ( <CreateOutlinedIcon /> );

	const cls = "contentgrid";
	const isReadonly = (params) => {
		let readonly = null;
		if (params.row.externalQuoteNumber && params.row.externalQuoteNumber.length > 0) {
			readonly = true;
		}

		return readonly;
	};

	function handlePriceSources(row) {
		// Add logic to handle price sources
	}

	return [
		{
			field: "selection",
			width: 100,
			sortable: false,
			headerCheckboxSelection: true,
			filterable: false,
			disableColumnMenu: true,
			renderHeader: () => (
				<input
					type="checkbox"
					checked={props.selectedRows.length === props.gridRows.length}
					onChange={(event) => props.handleSelectAll(event.target.checked)}
					style={{ accentColor: "#2E3F80", pointerEvents: "all", cursor: "pointer"}}
					disabled={isDisabledQuoteActions}
				/>
			),
			renderCell: (params) => (
				<div style={{position:"relative"}}>
					<div style={{position:"absolute", top:"-32px", fontStyle:"italic", 
						left: cutRows.includes(params.row.idQuoteItems) ? "-3px" :"-11px"
					}}>
						{cutRows.includes(params.row.idQuoteItems) && "Cut"}
						{copiedRows.includes(params.row.idQuoteItems)  && "Copied"}
					</div>
					<input
						type="checkbox"
						checked={props.selectedRows.includes(params.row.idQuoteItems)}
						onChange={(event) =>
							props.handleRowSelection(params.row.idQuoteItems, event.target.checked)
						}
						style={{ accentColor: "#2E3F80" }}
						disabled={isDisabledQuoteActions}
					/>
				</div>
			)
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 200,
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			renderCell: (params) => (
			params.row.isUnsaved ? (
				<Stack spacing={2} justifyContent="center" direction="row" >
			<CircularProgress size={30} />
			</Stack>
			) : (
				<div>
					<div>
						<Tooltip title={!props.canModifyProtectedItem && params.row.IsProtectedItem ? 'View Product' : tooltip}>
							<IconButton
								color="default"
								onClick={() => props.handleEdit(params.row)}
								sx={{ minWidth: 0 }}
							>
								{!props.canModifyProtectedItem && params.row.IsProtectedItem ? <RemoveRedEyeOutlinedIcon /> : icon}
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete Product">
							<IconButton
								color="default"
								onClick={() => props.handleDelete(params.row)}
								disabled={(!props.canModifyProtectedItem && params.row.IsProtectedItem) || isDisabledQuoteActions}
								sx={{ minWidth: 0 }}
							>
								<DeleteOutlineOutlinedIcon />
							</IconButton>
						</Tooltip>
						{!props.grid.state.priceSourcesLoaded ? (
							<CircularProgress size={20} />
						) : (
							<Tooltip title="Price Sources">
								<span>
									<IconButton
										color="default"
										onClick={(event) =>
											props.handlePriceSourceModalClick(event, params)
										}
										disabled={params.row.comment 
											|| props.isStandardUserAndProtectedTab 
											|| isDisabledQuoteActions 
											|| (!props.canModifyProtectedItem && params.row.IsProtectedItem)
										}
										sx={{ minWidth: 0 }}
									>
										<CloudOutlinedIcon />
									</IconButton>
								</span>
							</Tooltip>
						)}
					</div>
					{params.row.IsOptional && <Chip
						label="Optional"
						sx={{
							width:"120px", minWidth:"30px", marginTop:"-8px", height:"12px", disaply:"flex", 
							backgroundColor:"#f44336", lineHeight:"10px", fontSize:"10px", position:"absolute" ,
							color:"#fff"
						}}
					/>}
				</div> )
			)
		}
	];
}
