import React, { memo, useMemo, useState, useCallback, useEffect } from "react";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import EditorToToolbarWrapper from "../EditorToToolbarWrapper";
import useEditorWrapperHelper from "../EditorWrapper/editorWrapperHelpers";
import { ResizerComponent } from "../Resizer/ResizerComponent";
import TabsWrapperComponent from "../TabsWrapper";
import { EditorContextProvider, useEditorContext } from "../context/editorContext";
import { PreviewContentProvider } from "../context/previewContentContext";
import { getCKEditorTabsIds } from "../helpers/getCKEditorTabsIds";
import { useCPQEditorContainer } from "./CPQEditorContainer";
import { SelectedRowProvider } from "../TabsWrapper/SelectedRowContext";
import useDarkMode from "../WidgetToolbar/widgetToolbarHelpers";

function CreatePreviewContent({ contentGrid }) {
	const { ckeditor, gridFormats, selectedTabs, setSelectedTabs, isQuoteTemplate } = useEditorContext();
	const { insertItemsByTabs } = useEditorWrapperHelper();
	const darkMode = useDarkMode();


	const hasUniqueSelectedTabs = useMemo(() => {
		const droppedIds = getCKEditorTabsIds(ckeditor?.id && ckeditor.getData());

		if (selectedTabs.length === 0 && droppedIds.length === 0)
		{
			return false;
		}
		 else 
		{
			return selectedTabs.every((tab) => !droppedIds.includes(tab.tabId)) || selectedTabs.length !== droppedIds.length;
		}
		
	}, [selectedTabs]);

	const toggleSelectAll = useCallback(() => {
		if (selectedTabs.length === app.currentQuote.Tabs.length) 
		{
			setSelectedTabs([]);
		} else
		{
			setSelectedTabs(app.currentQuote.Tabs.map((tab) => ({ tabId: tab.IdQuoteTabs, tabNumber: tab.TabNumber })));
		}
	}, [ selectedTabs, setSelectedTabs ]);

	const allTabsAreGridFormat = useMemo(() => {
		return app.currentQuote.Tabs.every(tab => gridFormats.includes(tab.Name));
	}, [app.currentQuote.Tabs]);

	const createContent = () => {
		const selectedTabIds = selectedTabs.map((tab) => tab.tabId);
		const addableTabs = app.currentQuote.Tabs.filter((tab) => selectedTabIds.includes(tab.IdQuoteTabs));
		ckeditor.setData("");
		const data = insertItemsByTabs(addableTabs);

		if (data !== "") {
			app.currentQuote.HTMLContentForAutoSaving = data;
			contentGrid.forceUpdate();
		}

		ckeditor.editing.view.focus();
	};

	const openDialog = function () {
		const editor = <div>This action will remove all data from the Preview Content and paste in all selected tabs. This action cannot be undone. </div>;
		Dialog.open({
			title: "Confirm Create Preview Content",
			onClose: Dialog.close,
			links: [{ title: "Cancel", callback: () => { Dialog.close(); }}, { title: "Create", callback: ()=> { createContent(); Dialog.close(); }}],
			message: editor
		});
	}
	//Only show the create preview content checkbox and text if editing a quote template
	if(!isQuoteTemplate || !quosal.util.isNewEditorPreviewContentEnabled()) {
		return null;
	}

	const [ isSelected, setIsSelected ] = useState(false);

	const handleSelectAllClick = () => {
		toggleSelectAll();
		setIsSelected(selectedTabs.length !== app.currentQuote.Tabs.length);
	};

	useEffect(() => {
		setIsSelected(selectedTabs.length === app.currentQuote.Tabs.length);
	}, [ selectedTabs ]);

	const buttonStyles = {
		padding: "3px 5px",
		marginRight: "1em",
		textTransform: "none",
		fontWeight: "normal",
		backgroundColor: "#2D7CB2",
	};

	const typographyStyles = {
		textTransform: "none",
		fontWeight: "normal",
		color: "#fff !important",
	};

	    const disabledButtonStyles = {
        backgroundColor: darkMode ? "#555" : "#ccc",
        color: darkMode ? "#888" : "#888",
        borderColor: darkMode ? "#555" : "#ccc",
        cursor: "not-allowed",
    };


	return (
		<Box sx={{ marginRight: "1em" }}>
			<Button
				id="select-all-button"
				onClick={handleSelectAllClick}
				className="dialogSecondaryButton"
				disabled={allTabsAreGridFormat}
				variant={isSelected ? "contained" : "outlined"}
				color="primary"
				disableElevation
				sx={{
					...buttonStyles,
					backgroundColor: isSelected ? "#2E3F80" : "#2D7CB2",
					"&:hover": {
						backgroundColor: isSelected ? "#2D7CB2" : "#2E3F80"
					},
				}}
			>
				<Typography sx={typographyStyles}>
					Select All
				</Typography>
			</Button>
			<Button
				id="create-preview-content-button"
				onClick={hasUniqueSelectedTabs ? (app.currentQuote.HTMLContentForAutoSaving ? openDialog : createContent) : undefined}
				className="dialogSecondaryButton"
				disabled={!hasUniqueSelectedTabs}
				variant="contained"
				color="primary"
				disableElevation
				sx={{
					...buttonStyles,
					...(!hasUniqueSelectedTabs && disabledButtonStyles),
					"&:hover": {
						backgroundColor: "#1E3F80"
					}
				}
				}
			>
				<Typography sx={typographyStyles}>
					Create Preview Content
				</Typography>
			</Button>
		</Box>
	);
}

// eslint-disable-next-line import/prefer-default-export
function CPQEditorComponent({
	contentGrid,
	rowActions,
	handleCKEditorSelectedTabId,
	buildNotesDialog,
	ckEditorRenderKey,
	handleSetCKEditor,
	editorFullScreen
}) {
	const {
		leftContentExpanded,
		rightContentExpanded,
		leftContentWidth,
		rightContentWidth,
		panelClasses,
		LEFT_HEADER,
		RIGHT_HEADER,
		isResizingAllowed,
		expandRightContent,
		handleContentResize,
	} = useCPQEditorContainer(contentGrid);

	const previewContentIsVisible = quosal.util.isNewPreviewContentVisible();

	return (
		<div className="quoteContentWrapper">
			<DndProvider backend={HTML5Backend}>
				<EditorContextProvider
					value={{
						contentGrid,
						rowActions,
						handleCKEditorSelectedTabId,
						buildNotesDialog,
						handleSetCKEditor,
						editorFullScreen
					}}
				>
					<PreviewContentProvider
					value={{
						currentQuote: app.currentQuote,
					}}
				>
					<Grid container spacing={2}>
						<Grid
							id="leftContent"
							item
							sx={{
								width: !previewContentIsVisible
									? "100%"
									: leftContentExpanded
										? "100%"
										: rightContentExpanded
											? 0
											: `${leftContentWidth}%`,
								display: !previewContentIsVisible ? "block" : rightContentExpanded ? "none" : "block"
							}}
						>
							{quosal.util.isNewEditorPreviewContentEnabled() && 
							<div className={panelClasses} style={{ display: "flex", alignItems: "center" }}>
								<h2 style={{marginRight:"auto"}}>{LEFT_HEADER}</h2>
								<CreatePreviewContent contentGrid={contentGrid} />
								<div
									className={leftContentExpanded ? "collapseIcon" : "expandIcon"}
									onClick={contentGrid.updatePreviewVisible}
								/>
							</div>}
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									overflow: "auto"
								}}
							>
								<SelectedRowProvider>
									<TabsWrapperComponent expanded={leftContentExpanded} />
								</SelectedRowProvider>
							</Box>
						</Grid>
						{previewContentIsVisible &&
							<Grid
								item
								sx={{
									display:
										leftContentExpanded || rightContentExpanded || !isResizingAllowed ? "none" : "block"
								}}
							>
								<div className="panelDivider" style={{ position: "relative" }}>
									<ResizerComponent onResize={handleContentResize} />
									<DragIndicatorIcon />
								</div>
							</Grid>
						}
						{previewContentIsVisible &&
							<Grid
								key={ckEditorRenderKey}
								id="rightContent"
								item
								sx={{
									width: rightContentExpanded
										? "100%"
										: leftContentExpanded
											? 0
											: `${rightContentWidth}%`,
									paddingLeft: "20px !important",
									display: leftContentExpanded ? "none" : "flex"
								}}
							>
								<Grid
									container
									justifyContent="center"
									sx={{ display: "block", float: "right", alignItems: "flex-start" }}
								>
									<EditorToToolbarWrapper
										panelClasses={panelClasses}
										RIGHT_HEADER={RIGHT_HEADER}
										rightContentExpanded={rightContentExpanded}
										expandContent={expandRightContent}
									/>
								</Grid>
							</Grid>
						}
					</Grid>
					</PreviewContentProvider>
				</EditorContextProvider>
			</DndProvider>
		</div>
	);
}

export default memo(CPQEditorComponent);
