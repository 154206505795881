import { createContext, useEffect, useState, useRef, useCallback, useContext } from "react";

const QuoteContext = createContext();
export const useAppQuoteContext = () => useContext(QuoteContext);


export const AppQuoteProvider = ({ children }) => {
    const idQuoteMainRef = useRef(quosal.util.queryString('idquotemain'));
    const [updatingQuote, setUpdatingQuote] = useState(false);
    const [needsManualSync, setNeedsManualSync] = useState(false);
    const [quote, setQuote] = useState(window.app?.currentQuote);

    useEffect(() => {
        idQuoteMainRef.current = quosal.util.queryString('idquotemain');
    }, [idQuoteMainRef.current, quosal.util.queryString('idquotemain')]);

    //Use this callback when updating items and you do not want to wait for the api for the updates
    const updateQuoteItems = useCallback((updateFn) => {
        setQuote((prevQuote) => {
            return {
                ...prevQuote,
                Items: updateFn(prevQuote.Items)
            };
        });
    }, []);

    //Use this callback when updating tabs and you do not want to wait for the api for the updates
    const updateQuoteTabs = useCallback((updateFn) => {
        setQuote((prevQuote) => {
            return {
                ...prevQuote,
                Tabs: updateFn(prevQuote.Tabs)
            };
        });
    }, []);

    const partialItemUpdate = useCallback( (item, fieldChangeUpdates, callback) => {
        const updateApi = quosal.api.data.updatePartial(
            fieldChangeUpdates,
            app.currentQuote.IdQuoteMain,
        );
    
        updateApi.finished = function (msg) {
            setNeedsManualSync(true);

            //Update the quote object with the new item.
            msg.partialResponse =  {};
            msg.partialResponse.items = [];
            msg.partialResponse.items.push(item);

            //updatePartial will eventually splat the itam array and then call setQuote. 
            quosal.sell.quote.updatePartial(msg.partialResponse);
            callback?.(msg);
        };
    
        updateApi.call();
    }, []);


    const manualSyncEnabled = quote?.IsManualTotal;
    quosal.ui.react.setQuote = setQuote;
    quosal.ui.react.setNeedsManualSync = setNeedsManualSync;
    quosal.ui.react.partialItemUpdate = partialItemUpdate;

    useEffect(() => {
        if (!quote && window.app?.currentQuote) {
            setQuote(window.app.currentQuote);
        }
    }, [window.app?.currentQuote]);

    const updateQuoteTotal = useCallback(() => {
        var forceUpdateApi = quosal.api.quote.forceUpdate(idQuoteMainRef.current, true);
        forceUpdateApi.finished = function (msg) {
            quosal.sell.quote.updateFromApiResponse(msg);
            setNeedsManualSync(false);
            setUpdatingQuote(false);
        }.bind(this);

        forceUpdateApi.call();
        setUpdatingQuote(true);
    }, [idQuoteMainRef.current]);


   

    const createPartialResponseFromNewItem = useCallback((item)=>{
          //Update the quote object with the new item.
          let msg = {};
          msg.partialResponse =  {};
          msg.partialResponse.newItems = [item];
          return msg;
    }, []);

    return <QuoteContext.Provider value={{
        quote,
        manualSyncEnabled,
        hasManualSyncWarning: needsManualSync && manualSyncEnabled,
        setNeedsManualSync,
        updateQuoteTotal,
        updatingQuote,
        partialItemUpdate,
        updateQuoteItems,
        updateQuoteTabs,
        createPartialResponseFromNewItem,
    }}>
        {children}
    </QuoteContext.Provider>;
};

AppQuoteProvider.Consumer = QuoteContext.Consumer;


